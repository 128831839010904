'use strict';

const styles = theme => ({
    categoryHeader: {
		paddingTop: 16,
		paddingBottom: 16,
	},
	categoryNoHeader: {
		paddingTop: 16,
	},
	categoryHeaderPrimary: {
		color: theme.palette.common.white,
	},
	item: {
		paddingTop: 4,
		paddingBottom: 4,
		color: 'rgba(255, 255, 255, 0.7)',
	},
	itemCategory: {
		backgroundColor: '#ffffff',
		color: '#000000',
		boxShadow: '0 -1px 0 #404854 inset',
		paddingTop: 16,
		paddingBottom: 16,
	},
	firebase: {
		fontSize: 20,
		fontFamily: theme.typography.fontFamily,
		color: theme.palette.common.black,
	},
	itemActionable: {
		'&:hover': {
			backgroundColor: 'rgba(255, 255, 255, 0.08)',
		},
	},
	itemActiveItem: {
		color: '#4fc3f7',
	},
	itemPrimary: {
		color: 'inherit',
		fontSize: theme.typography.fontSize,
		'&$textDense': {
			fontSize: theme.typography.fontSize,
		},
	},
	textDense: {},
	divider: {
		marginTop: theme.spacing.unit * 2,
	},
	test: {
		backgroundColor: '#ffffff',
	}
});

export default styles;