import React from 'react';
import { Link } from 'gatsby';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { withStyles } from '@material-ui/core/styles';
import { Divider, Drawer, List, ListItem, ListItemIcon, 
	ListItemText } from '@material-ui/core';
import ApartmentIcon from '@material-ui/icons/Apartment';
import HomeIcon from '@material-ui/icons/Home';
import CropFreeIcon from '@material-ui/icons/CropFree';
import TrendingUpIcon from '@material-ui/icons/TrendingUp';
import FindInPageIcon from '@material-ui/icons/FindInPage';
import ReceiptIcon from '@material-ui/icons/Receipt';
import Image from 'components/Image';
import styles from './styles';

const categories = [
	{
		id: 'basic',
		children: [
			{ id: 'Account', icon: <ApartmentIcon />, page: 'account' },
			{ id: 'Codes', icon: <CropFreeIcon />, page: 'codes' },
		]
	},
	{
		id: 'applications',
		children: [
			{ id: 'Add Application', icon: <ApartmentIcon />, page: 'account' },
		]
	},
	{
		id: 'reporting',
		title: 'Reporting',
		children: [
			{ id: 'Analyses', icon: <FindInPageIcon />, page: 'analytics' },
			{ id: 'Consumptions', icon: <TrendingUpIcon />, page: 'performance' },
			{ id: 'Costs', icon: <ReceiptIcon />, page: 'lab' },
		],
	},
];

function Navigator({ classes, location = null, applications = null, ...rest }) {
	const matchPath = location ? location.pathname.replace(/\//g, '') : null;
	let extended_categories = [];
	for (let category of categories) {
		if (category.id != 'applications') {
			extended_categories.push(category);
		} else if (applications) {
			for (const appName of applications.applicationKeys) {
				const application = applications.apps[appName];
				extended_categories.push({
					id: application.title,
					title: application.title,
					children: application.applicationObjects.map(applicationObjects => {
						return { id: applicationObjects.objectsName, icon: applicationObjects.icon, 
							page: applicationObjects.page }
					})
				});
			}	
		}
	}

	return (
		<Drawer variant="permanent" {...rest}>
			<List disablePadding>
				<ListItem className={classNames(classes.test)}>
					<Image 
						imgStyle={{
							position: "relative",
							height: "80%"
						  }}
						placeholderStyle={{
							display: "none"
						}}
						fileName="logo.png"
						alt="logo.png"
					/>
				</ListItem>
				
				<Link style={{ textDecoration: 'none', color: 'inherit' }} to="/">
					<ListItem className={classNames(classes.item, classes.itemCategory)}>
						<ListItemIcon>
							<HomeIcon />
						</ListItemIcon>
						<ListItemText
							classes={{
								primary: classes.itemPrimary,
							}}
						>
							Welcome
						</ListItemText>
					</ListItem>
				</Link>
				{extended_categories.map(({ id, title, children }) => (
					<React.Fragment key={id}>
						{(title) ? 
							<ListItem className={classes.categoryHeader}>
								<ListItemText
									classes={{
										primary: classes.categoryHeaderPrimary,
									}}
								>
									{title}
								</ListItemText>
							</ListItem>
						: <div className={classes.categoryNoHeader}></div> }
						{children.map(({ id: childId, icon, page = null }) => {
							return page ? (
								<Link
									key={childId}
									style={{ textDecoration: 'none', color: 'inherit' }}
									to={`/${page}`}
								>
									<ListItem
										button
										dense
										className={classNames(
											classes.item,
											classes.itemActionable,
											(matchPath && page ? matchPath === page.replace(/\//g, '') : false) && classes.itemActiveItem
										)}
									>
										<ListItemIcon>{icon}</ListItemIcon>
										<ListItemText
											classes={{
												primary: classes.itemPrimary
											}}
										>
											{childId}
										</ListItemText>
									</ListItem>
								</Link>
							) : (
								<div />
							)
						})}
						<Divider className={classes.divider} />
					</React.Fragment>
				))}
			</List>
		</Drawer>
	)
}

Navigator.propTypes = {
	classes: PropTypes.object.isRequired,
	location: PropTypes.object,
}

export default withStyles(styles)(Navigator)
