import React from 'react';
import PropTypes from 'prop-types';
import { AppBar, Menu, MenuItem, Button, Grid,
	Hidden, IconButton, Toolbar, Tooltip, Typography,
	List, ListItem } from '@material-ui/core';
import HelpIcon from '@material-ui/icons/Help';
import MenuIcon from '@material-ui/icons/Menu';
import { withStyles } from '@material-ui/core/styles';
import { Auth } from 'aws-amplify';
import AccountsContext from 'context/AccountsContext';
import TextPlaceholder from 'components/TextPlaceholder';
import styles from './styles';


function Header({ classes, onDrawerToggle, title, authData}) {

	const [accountSelector, setAccountSelector] = React.useState(null);
	const [userMenu, setUserMenu] = React.useState(null);

	const handleAccountButtonClick = (event) => {
		setAccountSelector(event.currentTarget);
	};

	const handleAccountSelectorClose = () => {
		setAccountSelector(null);
	};

	const handleUserMenuButtonClick = (event) => {
		setUserMenu(event.currentTarget);
	};

	const handleUserMenuClose = () => {
		setUserMenu(null);
	};

	const handleSignOut = () => {
		Auth.signOut().then(data => console.log(data))
		.catch(err => console.error(err));
	}

	const showAuthenticationCode = (event) => {
		alert(`Personal Authorization Code: ${authData?.signInUserSession.idToken.jwtToken}`);
	}

	const hmltAccountSelector = (
		<Grid item>
			<AccountsContext.Consumer>
			{acc => (
				<>
				<Button 
					aria-controls="simple-menu" 
					aria-haspopup="true" 
					variant="outlined" 
					color="inherit"
					size="small"
					onClick={handleAccountButtonClick}
				>
					{acc?.accounts[acc?.selectedAccount]?.name}
				</Button>
				<Menu
					id="simple-menu"
					anchorEl={accountSelector}
					keepMounted
					open={Boolean(accountSelector)}
					onClose={handleAccountSelectorClose}
				>
				{Object.entries(acc?.accounts).map((pair, index) => (
					<MenuItem
						key={pair[1].name}
						selected={pair[0] === acc.selectedAccount}
						onClick={() => {
							acc.setSelectedAccount(pair[0]);
							setAccountSelector(null);
						}}
					>
						{pair[1].name}
					</MenuItem>
				))}
				</Menu>
				</>
			)}
			</AccountsContext.Consumer>
		</Grid>
	);

	const htmlUserSession = (	
		<div>
			<Button 
				aria-controls="simple-menu" 
				aria-haspopup="true" 
				variant="text" 
				color="inherit"
				size="small"
				onClick={handleUserMenuButtonClick}
			>
				{authData?.attributes.email}
			</Button>
			<Menu
				id="simple-menu"
				anchorEl={userMenu}
				keepMounted
				open={Boolean(userMenu)}
				onClose={handleUserMenuClose}
			>
				<List>
					<ListItem>
						<Button 
							onClick={showAuthenticationCode}
						>Pers. Code</Button>
					</ListItem>
					<ListItem>
						<Button 
							onClick={handleSignOut}
						>SignOut</Button>
					</ListItem>
				</List>
			</Menu>
		</div>
	);
	

	return (
		<>
			<AppBar color="primary" position="sticky" elevation={0}>
				<Toolbar>
					<Grid container spacing={8} alignItems="center">
						<Hidden smUp>
							<Grid item>
								<IconButton
									color="inherit"
									aria-label="Open drawer"
									onClick={onDrawerToggle}
									className={classes.menuButton}
								>
									<MenuIcon />
								</IconButton>
							</Grid>
						</Hidden>
						{hmltAccountSelector}
						<Grid item xs />
						<Grid item>
							{htmlUserSession}
						</Grid>
					</Grid>
				</Toolbar>
			</AppBar>
			<AppBar component="div" className={classes.secondaryBar} color="primary" position="static" elevation={0}>
				<Toolbar>
					<Grid container alignItems="center" spacing={8}>
						<Grid item xs>
							{(title) ? (
								<Typography style={{ textTransform: 'capitalize' }} color="inherit" variant="h5">
									{title}
								</Typography>
							) : (
								<TextPlaceholder />
							)}
						</Grid>
						<Grid item>
							<Tooltip title="Help">
								<IconButton color="inherit">
									<HelpIcon />
								</IconButton>
							</Tooltip>
						</Grid>
					</Grid>
				</Toolbar>
			</AppBar>
		</>
	);
}

Header.propTypes = {
	classes: PropTypes.object.isRequired,
	onDrawerToggle: PropTypes.func.isRequired,
	title: PropTypes.string,
	authData: PropTypes.object.isRequired
}

export default withStyles(styles)(Header)
