import React, { Component } from "react";
import ContentLoader from "react-content-loader";

class TextPlaceholder extends Component {
    render() {
        return (
            <ContentLoader 
                speed={2}
                width='100%'
                height={20}
                backgroundColor={'#fff'}
                foregroundColor={'#183C63'}
                style={{ marginBottom: "4px" }}
            >
                <rect x="0" y="10" rx="3" ry="3" width="300" height="6" /> 
            </ContentLoader>
        );
    }
}

export default TextPlaceholder